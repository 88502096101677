import React from 'react';

const useToggle = (defaultValue = false) => {
  const [on, setIsOn] = React.useState(defaultValue);

  const setOn = () => setIsOn(true);
  const setOff = () => setIsOn(false);
  const toggle = () => setIsOn((v) => !v);

  return { on, setOn, setOff, toggle };
};

export default useToggle;
